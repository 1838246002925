/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { trls } from "../../factories/translate";
import "react-datepicker/dist/react-datepicker.css";
import API from "../../factories/api";
import * as Common from "../../factories/common";
import Axios from "axios";
import * as Auth from "../../factories/auth";
import SessionManager from "../../factories/session_manage";
import $ from "jquery";
import Pageloadspiiner from "../../components/page_load_spinner";
import history from "../../history";
import Sweetalert from "sweetalert";

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({});

class Newitemform extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    const { editOrderRowData } = this.props;
    this.state = {
      itemCode: editOrderRowData.ItemCode ? editOrderRowData.ItemCode : "",
      itemData: [],
      itemFlag: false,
      pageLodingFlag: false,
      quantity: editOrderRowData.order_quantity
        ? editOrderRowData.order_quantity
        : 0,
      customerReference: editOrderRowData.order_customerreference
        ? editOrderRowData.order_customerreference
        : "",
      itemEnable: false,
    };
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    $(document).on("keypress", ":input", function (e) {
      if (e.which === 13) e.preventDefault();
    });
    $("input[name='itemcode']").focus();
    $(".modal").removeAttr("tabindex");
  }

  handleSubmit = (event) => {
    this.setState({ pageLodingFlag: true });
    const {
      patternCalculateCheck,
      itemQuantityData,
      editPatternCalcuRow,
      orderLineId,
    } = this.props;
    const { itemFlag } = this.state;
    this._isMounted = true;
    event.preventDefault();
    const clientFormData = new FormData(event.target);
    const data = {};
    for (let key of clientFormData.keys()) {
      data[key] = clientFormData.get(key);
    }
    let quantity = 0;

    if (
      (patternCalculateCheck && !editPatternCalcuRow.rowId) ||
      (itemFlag && !itemQuantityData)
    ) {
      quantity = parseFloat(data.quantity);
    } else if (itemQuantityData) {
      quantity = parseFloat(itemQuantityData);
    }
    
    if(quantity === undefined || quantity === 0) {
      Sweetalert({
        text: trls(
          "Item quantity has to be higher than 0"
        ),
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
      this.setState({ pageLodingFlag: false });

      return;
    }

    var headers = SessionManager.shared().getAuthorizationHeader();
    let params = {
      orderlineid: orderLineId,
      productcode: data.itemcode ? data.itemcode : this.state.itemCode,
      quantity: quantity ? quantity.toFixed(2) : 0,
      customerreference: data.customerreference ? data.customerreference : "",
    };

    if (orderLineId) {
      Axios.put(API.UpdateOrderLine, params,headers)
        .then((result) => {
          let lineId = orderLineId;
          let duplicateItem = false;

          if(result.data.length > 0) {
            if(result?.data[0]?.OrderlineId) {
              if(orderLineId !== result.data[0].OrderlineId) {
                lineId = result.data[0].OrderlineId;
                this.setState({editOrderRowFlag: true});
                duplicateItem = true;
              }
            }
          }

          Axios.get(API.GetObsolete+lineId,headers).then((result) => {
            let status = result.data.status;
            if (
              status === "Not obsolete" ||
              status === "Obsolete but with stock"
            ) {
              var settings = {
                url: API.GetCalculateDataPrice+lineId,
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + Auth.getUserToken(),
                },
              };
              $.ajax(settings)
                .then((response) => {
                  this.setOrderItem(
                    data.itemcode ? data.itemcode : this.state.itemCode,
                    quantity,
                    response.discountPriceResponse["NewUnitPrice"],
                    data.customerreference,
                    response.deliveryDate,
                    "",
                    duplicateItem
                  );
                })
                .catch((err) => {
                  if (err.response.status === 401) {
                    history.push("/login");
                  }
                });
            } else {
              Sweetalert({
                text: trls(
                  "Item has been discontinued and is not sufficiently available"
                ),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                var settings = {
                  url: API.DeleteOrderLine + "?orderlineId=" + lineId,
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Auth.getUserToken(),
                  },
                };
                if (willDelete) {
                  this.setState({ pageLodingFlag: true });
                  $.ajax(settings)
                    .done(function (response) {})
                    .then((response) => {
                      this.setState({ pageLodingFlag: false });
                      this.onHide(true);
                    })
                    .catch((err) => {
                      this.displayError(err);
                    });
                } else {
                  if (status === "Obsolete with no stock") {
                    this.setState({ pageLodingFlag: true });
                    $.ajax(settings)
                      .done(function (response) {})
                      .then((response) => {
                        this.setState({ pageLodingFlag: false });
                        this.onHide(true);
                      })
                      .catch((err) => {
                        this.displayError(err);
                      });
                  }
                }
              });
            }
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            history.push("/login");
          }
        });
    }
  };

  setOrderItem = (
    itemCode,
    quantity,
    price,
    customerreference,
    deliveryWeek,
    priceData,
    update
  ) => {
    const {
      setItemCodeFlag,
      patternCalculateCheck,
      editOrderRowFlag,
      editOrderRowData,
      orderLineId,
    } = this.props;
    const { itemFlag } = this.state;
    var headers = SessionManager.shared().getAuthorizationHeader();
    this._isMounted = true;
    let itemData = [];
    if (itemCode) {
      if (this._isMounted) {
        if (!editOrderRowFlag || itemFlag) {
          if (!setItemCodeFlag) {
            itemData = this.state.itemData;
          } else {
            itemData = this.props.itemData;
          }
        } else {
          if (setItemCodeFlag) {
            itemData = this.props.itemData;
          } else {
            itemData = editOrderRowData;
          }
        }
        if (!itemData.ItemCode) {
          this.setState({ itemEnable: false });
          return;
        }
        Axios.get(API.GetItemDataByItemCode + itemData.ItemCode, headers)
          .then((result) => {
            itemData.Image = result.data.Image;
            itemData.order_quantity = quantity;
            itemData.order_price = price;
            itemData.orderPriceData = priceData;
            itemData.order_customerreference = customerreference;
            itemData.patterCalculateCheck = patternCalculateCheck;
            itemData.order_deliveryWeek = deliveryWeek;
            itemData.orderLineId = orderLineId;
            this.setState({ pageLodingFlag: false });

            if(update) {
              this.props.onIncreaseQuantity(itemData);
            } else if (!editOrderRowFlag) {
              this.props.onAddOrderRow(itemData);
            } else {
              this.props.updateOrderRowLine(itemData);
            }
            this.onHide(true);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              history.push("/login");
            }
          });
      }
    } else {
      Sweetalert({
        text: "Invalid item code",
        icon: "error",
      });
    }
  };

  getItemData = () => {
    var headers = SessionManager.shared().getAuthorizationHeader();
    this._isMounted = true;
    let itemCodeData = "";
    const { itemCode } = this.state;
    const { setItemCodeFlag, itemData } = this.props;
    if (setItemCodeFlag) {
      itemCodeData = itemData.ItemCode;
    } else {
      itemCodeData = itemCode;
    }
    let code = 0;
    if (itemCodeData !== "") {
      code = itemCodeData;
      this.setState({ pageLodingFlag: true });
      var settings = {
        url: API.GetItemDataByItemCode + itemCodeData,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Auth.getUserToken(),
        },
      };
      $.ajax(settings)
        .done(function (response) {})
        .then((response) => {
          this.setState(
            {
              itemData: response,
              itemFlag: true,
              pageLodingFlag: false,
              itemEnable: true,
            },
            () => {
              this.props.checkPatternCalculate(itemCodeData);
              $(".fade").attr("tabindex", "disable");
            }
          );
          if (!response) {
            this.setState({ pageLodingFlag: false, itemEnable: false });
            $(".fade").attr("tabindex", "disable");
            this.props.searchItemForm(itemCodeData);
          }
        })
        .catch((err) => {
          this.setState({ pageLodingFlag: false, itemEnable: false });
          $(".fade").attr("tabindex", "disable");
          this.props.searchItemForm(itemCodeData);
        });
    }
  };

  showSearchItem = () => {
    let itemCodeData = "";
    const { itemCode } = this.state;
    const { setItemCodeFlag, itemData } = this.props;
    if (setItemCodeFlag) {
      itemCodeData = itemData.ItemCode;
    } else {
      itemCodeData = itemCode;
    }
    this.props.searchItemForm(itemCodeData);
    $(".fade").attr("tabindex", "disable");
  };

  changeItemCode = (e) => {
    this.setState({ itemCode: e.target.value });
    this.props.onSetItemCodeFlag();
  };

  onHide = (mode) => {
    this.setState({
      itemCode: "",
      itemData: [],
      itemFlag: false,
      pageLodingFlag: false,
      quantity: "",
    });
    const { orderLineId } = this.props;
    this.props.onHide(orderLineId, mode);
  };

  editPatternCalculate = () => {
    $(".fade").attr("tabindex", "disable");
    this.props.calculatePattern();
  };

  changeQuantity = (value) => {
    if(value > 100) {
      Sweetalert({
        text: trls(
          "Is this number correct?"
        ),
        icon: "warning",
      })
    }
    this.setState({ quantity: value })
  }

  render() {
    const {
      quantity,
      itemFlag,
      itemCode,
      pageLodingFlag,
      customerReference,
      itemEnable,
    } = this.state;
    const {
      itemQuantityData,
      itemData,
      patternCalculateCheck,
      setItemCodeFlag,
      itemSearchformFlag,
      editPatternCalcuRow,
      noItemMsg,
    } = this.props;
    // const { itemQuantityData, itemData, patternCalculateCheck, setItemCodeFlag, itemSearchformFlag, slidePatternFormFlag, editPatternCalcuRow, noItemMsg } = this.props;
    return (
      <Modal
        show={this.props.show}
        onHide={() => this.onHide(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="false"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.editOrderRowFlag ? trls("Edit"): trls("New Item")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Col className="product-text">
                {noItemMsg ? (
                  <Form.Control
                    type="text"
                    name="itemcode"
                    autoComplete="off"
                    autoFocus
                    required
                    disabled={itemSearchformFlag || this.props.editOrderRowFlag}
                    className={
                      !itemEnable
                        ? "place-order__product-code active"
                        : "place-order__product-code"
                    }
                    placeholder={trls("Product_code")}
                    onChange={(e) => this.changeItemCode(e)}
                    onBlur={this.getItemData}
                  />
                ) : (
                  <Form.Control
                    type="text"
                    name="itemcode"
                    autoComplete="off"
                    autoFocus
                    required
                    disabled={itemSearchformFlag || this.props.editOrderRowFlag}
                    value={setItemCodeFlag ? itemData.ItemCode : itemCode}
                    className={
                      !itemEnable
                        ? "place-order__product-code active"
                        : "place-order__product-code"
                    }
                    placeholder={trls("Product_code")}
                    onChange={(e) => this.changeItemCode(e)}
                    onBlur={this.getItemData}
                  />
                )}

                <label className="placeholder-label">
                  {trls("Product_code")}
                </label>
                <i
                  className="fas fa-search place-an-order__loop"
                  aria-hidden="true"
                  onClick={this.showSearchItem}
                ></i>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Col className="product-text">
                <Form.Control
                  type="text"
                  name="quantity"
                  required
                  disabled={
                    !patternCalculateCheck ||
                    itemSearchformFlag ||
                    (editPatternCalcuRow.rowId && !itemFlag)
                      ? true
                      : false
                  }
                  value={
                    (!patternCalculateCheck || itemQuantityData) && !this.props.editOrderRowFlag
                      ? itemQuantityData
                      : quantity
                  }
                  placeholder={trls("Quantity")}
                  onChange={(e) => this.changeQuantity(e.target.value)}
                />
                <label className="placeholder-label">{trls("Quantity")}</label>
                {editPatternCalcuRow.rowId && !itemFlag && (
                  <i
                    className="fas fa-pen place-an-order__loop"
                    aria-hidden="true"
                    onClick={() => this.editPatternCalculate()}
                  ></i>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Col className="product-text">
                <Form.Control
                  type="text"
                  name="customerreference"
                  defaultValue={customerReference}
                />
                <label className="placeholder-label">
                  {trls("Commission")}
                </label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Col className="product-text">
                {noItemMsg && (
                  <Alert variant="secondary">
                    <p>{noItemMsg}</p>
                  </Alert>
                )}
              </Col>
            </Form.Group>
            <Form.Group style={{ textAlign: "center" }}>
              <Button type="Submit" disabled={noItemMsg}>
                {trls("Submit")}
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Pageloadspiiner loading={pageLodingFlag} text={""} />
      </Modal>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Newitemform);
