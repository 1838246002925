const BASEURL = "https://eijffinger-api-prod.azurewebsites.net"; // DEV
//const BASEURL = "https://eijffinger-api-prod.azurewebsites.net" // PROD

export default {
  CalculateDeliveryDateAndDiscountPrice: `${BASEURL}/api/DeliveryNotes/CalculateDeliveryDateAndDiscountPrice/`,
  GetToken: `${BASEURL}/api/Token`,
  PostResetPassword: `${BASEURL}/api/Account/ResetPassword`,
  GetOrdersData: `${BASEURL}/api/SapFactory/execute/get/OrderlinesOverview`,
  GetUserData: `${BASEURL}/api/users/SearchUser`,
  GetOrderDetailsHeader: `${BASEURL}/api/StoredProcedure/SP_OrderDetailsHeader?ordernumber=`,
  getOrderLineData: `${BASEURL}/api/StoredProcedure/SP_OrderDetailsLines?ordernumber=`,
  PostUserData: `${BASEURL}/api/users/CreateWithoutSAP`,
  GetUserDataById: `${BASEURL}/api/users/GetById/`,
  PostUserUpdate: `${BASEURL}/api/users/Update/`,
  PostDeaActivateUser: `${BASEURL}/api/users/DeactivateUser/`,
  PostActivateUser: `${BASEURL}/api/users/ActivateUser/`,
  PostForgotPassEmail: `${BASEURL}/api/Account/ForgotPassword/`,
  GetDeliveriesData: `${BASEURL}/api/SapFactory/execute/get/GetDeliveries`,
  PosReturnRequestData: `${BASEURL}/api/SapFactory/execute/post/ReturnRequest`,
  GetReturnsData: `${BASEURL}/api/SapFactory/execute/get/ReturnlinesOverview`,
  GetSalesInvoicesData: `${BASEURL}/api/SapFactory/execute/get/InvoicelinesOverview`,
  GetCreditNotesData: `${BASEURL}/api/SapFactory/execute/get/CreditNotes`,
  GetInvoiceByDate: `${BASEURL}/api/SapFactory/execute/get/InvoiceByDate`,
  GetLastOrdersData: `${BASEURL}/api/SapFactory/execute/get/Latest5Orders`,
  GetLastDelivriesData: `${BASEURL}/api/SapFactory/execute/get/Latest5Deliverables`,
  GetLastOutstandingData: `${BASEURL}/api/SapFactory/execute/get/Latest5OutstandingInvoices`,
  GetDownloadFile: `${BASEURL}/api/SapProxy/pdf`,
  PostLoginAsUser: `${BASEURL}/api/Token/LoginAs/`,
  GetCustomerData: `${BASEURL}/api/SapFactory/execute/get/GetCustomer`,
  GetItemData: `${BASEURL}/api/Items/StockItems/`,
  GetItemDataByItemCode: `${BASEURL}/api/Items/`,
  GetDiscountPrice: `${BASEURL}/api/SapProxy/discountprice`,
  GetDashboardData: `${BASEURL}/api/Dashboard`,
  PostItems: `${BASEURL}/api/Items`,
  GetOrderDetails: `${BASEURL}/api/Orders/GetOrderDetails/`,
  PostPatroonberekening: `${BASEURL}/api/SapProxy/patroonberekening`,
  PostOrder: `${BASEURL}/api/SapFactory/execute/post/PostOrder`,
  PatchOrder: `${BASEURL}/api/SapFactory/execute/patch/PatchOrder`,
  GetOrderExpenses: `${BASEURL}/api/SapProxy/orderexpenses`,
  GetVatGroups: `${BASEURL}/api/Documents/getvatgroups`,
  GetReturnDetails: `${BASEURL}/api/Orders/GetReturnDetails/`,
  GetInvoiceDetails: `${BASEURL}/api/Orders/GetInvoiceDetails/`,
  GetDeliveryDetails: `${BASEURL}/api/Orders/GetDeliveryDetails/`,
  GetNews: `${BASEURL}/api/News`,
  Postnews: `${BASEURL}/api/News`,
  DeleteNewsDocument: `${BASEURL}/api/News/DeleteNewsDocument/news/`,
  AddNewsDocuments: `${BASEURL}/api/News/AddDocuments/`,
  GetNewsDataById: `${BASEURL}/api/News/`,
  UpdateNews: `${BASEURL}/api/News/Update/`,
  DeleteNews: `${BASEURL}/api/News/Delete/`,
  ReturnOrder: `${BASEURL}/api/SapFactory/execute/post/GoodsReturnRequest`,
  PostDeliveryDate: `${BASEURL}/api/SapProxy/deliverydate`,
  GetDeliveryDate: `${BASEURL}/api/StoredProcedure/SP_GetDeliveryDate`,
  LoggedUserProfileUpdate: `${BASEURL}/api/Account/UpdateProfile`,
  GetItemByDeliveryWeek: `${BASEURL}/api/Orders/ItemByDeliveryWeek/`,
  GetSampleItemByItemCode: `${BASEURL}/api/SampleItems/`,
  SearchSampleItem: `${BASEURL}/api/SampleItems`,
  GetShippingAddresses: `${BASEURL}/api/ShippingAddress/GetByCustomerNumber/`,
  PostShippingAddress: `${BASEURL}/api/ShippingAddress`,
  GetSalesInvoices: `${BASEURL}/api/StoredProcedure/SP_getInvoiceLines`,
  GetDeliveryNotes: `${BASEURL}/api/SapFactory/execute/get/DeliverylinesOverview`,
  GetOutstandingInvoicesData: `${BASEURL}/api/SapFactory/execute/get/OpenInvoicelinesOverview`,
  GetAllHelpText: `${BASEURL}/api/HelpText`,
  PostHelpText: `${BASEURL}/api/HelpText`,
  GetHelpText: `${BASEURL}/api/HelpText/`,
  UpdateHelpText: `${BASEURL}/api/HelpText/Update/`,
  GetHelpTextByName: `${BASEURL}/api/HelpText/GetByPageName/`,
  DeleteHelpText: `${BASEURL}/api/HelpText/Delete/`,
  GetAllDocumentType: `${BASEURL}/api/DocumentTypes`,
  PostDocumentType: `${BASEURL}/api/DocumentTypes`,
  GetDocumentType: `${BASEURL}/api/DocumentTypes/`,
  UpdateDocumentType: `${BASEURL}/api/DocumentTypes/`,
  DeleteDocumentType: `${BASEURL}/api/DocumentTypes/`,
  GetAllDocuments: `${BASEURL}/api/Documents`,
  PostDocuments: `${BASEURL}/api/Documents`,
  GetDocuments: `${BASEURL}/api/Documents/`,
  UpdateDocuments: `${BASEURL}/api/Documents/`,
  DeleteDocuments: `${BASEURL}/api/Documents/`,
  PostDocumentsfileBydocumentTypeId: `${BASEURL}/api/Documents/createWithFile/`,
  PostDocumentsfileId: `${BASEURL}/api/Documents/file/`,
  PostRegistration: `${BASEURL}/api/UserRegistration/Create`,
  GetAllRegistration: `${BASEURL}/api/UserRegistration/Get`,
  PostApprove: `${BASEURL}/api/UserRegistration/Approve/`,
  PostGetBatch: `${BASEURL}/api/SapProxy/batches`,
  PostWeggeefmeters: `${BASEURL}/api/SapProxy/weggeefmeters`,
  GetStockItems: `${BASEURL}/api/Items/StockItems/`,
  PostBatchtoekenning: `${BASEURL}/api/SapProxy/batchtoekenning/`,
  PostReturn: `${BASEURL}/api/SapFactory/execute/post/ReturnRequest`,
  GetNotifications: `${BASEURL}/api/Settings/notificationEmail`,
  DeleteNotification: `${BASEURL}/api/Settings/notificationemail/delete/`,
  PostNotification: `${BASEURL}/api/Settings/notificationemail/create`,
  GetTermsOfService: `${BASEURL}/api/Settings/termOfService`,
  UpdateTermsOfService: `${BASEURL}/api/Settings/termOfService/update`,
  PostSampleShippingTypes: `${BASEURL}/api/ShippingTypes`,
  PostShippingTypes: `${BASEURL}/api/SapProxy/couriers`,
  DeleteUser: `${BASEURL}/api/UserRegistration/delete/`,
  GetAllErrorLogs: `${BASEURL}/api/Logs`,
  GetLogsLevel: `${BASEURL}/api/Logs/levels`,
  GetLogsEvent: `${BASEURL}/api/Logs/events`,
  DeleteDeactivateUser: `${BASEURL}/api/users/`,
  DocumentsCustomerCodes: `${BASEURL}/api/Documents/customercodes`,
  GetCustomerDocuments: `${BASEURL}/api/Documents/bycustomer/`,
  GetExcludeProductgroups: `${BASEURL}/api/Settings/excludeProductgroups`,
  PutExcludeProductgroups: `${BASEURL}/api/Settings/excludeProductgroups/update/`,
  PutShippingAddress: `${BASEURL}/api/ShippingAddress/Update/`,
  PostExpenses: `${BASEURL}/api/SapProxy/genericexpenses`,
  GetCountry: `${BASEURL}/api/Country`,
  GetCountryName: `${BASEURL}/api/Country/getbycountrycode/`,
  PostOrderHeader: `${BASEURL}/api/StoredProcedure/SP_postOrderHeader`,
  GetOrderHeader: `${BASEURL}/api/StoredProcedure/SP_getCreateOrderHeader`,
  UpdateOrderHeader: `${BASEURL}/api/StoredProcedure/SP_updateOrderHeader`,
  ReturnLoadfromsap: `${BASEURL}/api/ImportSap/returnRequests/loadfromsap/`,
  OrderLoadfromsap: `${BASEURL}/api/ImportSap/orders/loadfromsap/`,
  GetMaintenance: `${BASEURL}/api/Settings/maintenance`,
  PutMaintenance: `${BASEURL}/api/Settings/maintenance`,
  PutMaintenanceOn: `${BASEURL}/api/Settings/maintenance/on`,
  PutMaintenanceOff: `${BASEURL}/api/Settings/maintenance/off`,
  PostOndermaten: `${BASEURL}/api/Orders/postOndermaten`,
  PostSPorderLine: `${BASEURL}/api/StoredProcedure/SP_postOrderLine?orderHeaderId=`,
  UpdateOrderLine: `${BASEURL}/api/StoredProcedure/SP_updateOrderline`,
  PostOrderLinePattern: `${BASEURL}/api/StoredProcedure/SP_postOrderlinePattern`,
  PostOrderSP: `${BASEURL}/api/Orders/postOrder?OrderHeaderId=`,
  GetItemGroupList: `${BASEURL}/api/SapFactory/execute/get/GetItemGroups`,
  GetCustomerGroup: `${BASEURL}/api/SapFactory/execute/get/GetCustomerGroups`,
  GetNrSapShippingAddresses: `${BASEURL}/api/StoredProcedure/getNrSapShippingAddresses`,
  GetSPSapShippingAddresses: `${BASEURL}/api/StoredProcedure/SP_getSapShippingAddresses`,
  DeleteOrderLine: `${BASEURL}/api/StoredProcedure/SP_DeleteOrderLine`,
  GetCalculateDataPrice: `${BASEURL}​/api/DeliveryNotes/CalculateDeliveryDateAndDiscountPrice/`,
  DeleteOrderLinePatternId: `${BASEURL}/api/StoredProcedure/SP_DeleteOrderLinePattern`,
  ChangePassword: `${BASEURL}/api/Account/changepassword`,
  ChangeMyPassword: `${BASEURL}/api/Account/UpdateMyPassword`,
  GetStockOrderHeader: `${BASEURL}/api/StoredProcedure/SP_postStockOrderHeader`,
  GetStockOrderLine: `${BASEURL}/api/StoredProcedure/SP_postStockOrderLine?orderHeaderId=`,
  PostStockOrderLinePattern: `${BASEURL}/api/StoredProcedure/SP_postStockOrderlinePattern`,
  UpdateStockOrderLine: `${BASEURL}/api/StoredProcedure/SP_UpdateStockOrderLine`,
  GetStockInfo: `${BASEURL}/api/Orders/GetStockInfo?OrderHeaderId=`,
  GetOrderLines: `${BASEURL}/api/Orders/GetOrderLines`,
  GetDeliveryLines: `${BASEURL}/api/Delivery/GetDeliveryLines`,
  GetInvoices: `${BASEURL}/api/Invoiceline/GetInvoices`,
  GetOutstandingInvoices: `${BASEURL}/api/Invoiceline/GetOutstandingInvoices`,
  GetReturtLines: `${BASEURL}/api/Return/GetReturtLines`,
  GetObsolete: `${BASEURL}/api/Items/checkObsolete/`,
  GetAllNews: `${BASEURL}/api/News/GetAllNews`
};
