/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { trls } from '../../factories/translate';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import API from '../../factories/api'
import * as Common from '../../factories/common';
import Axios from 'axios';
import * as Auth from '../../factories/auth'
import SessionManager from '../../factories/session_manage';
import $ from 'jquery';
import { BallBeat } from 'react-pure-loaders';
import ItemSearchform from './Item_searchform';
import Patterncalculateform from './patterncalculate_form';
import Pageloadspiiner from '../../components/page_load_spinner';
import history from '../../history';
import Orderdetailform from './orderdetail_form';
import Shippingaddressform from './shippingaddress_form';
import Newitemform from './newitem_form';
import Sweetalert from 'sweetalert';
import * as authAction from '../../actions/authAction';
import ShippingAddressEdit from './edit_shippngAddress'
import HelpTextModal from '../Dashboard/HelpText';
import uuid from 'react-uuid'
import Moment from 'moment'


const mapStateToProps = state => ({
    ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({
    blankdispatch: (blankFlag) =>
        dispatch(authAction.blankdispatch(blankFlag)),
});

class Placemanage extends Component {

    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            businessPartnerOption: [],
            shippingAddressOption: [],
            itemData: [],
            itemCode: '',
            itemQuantity: 0,
            itemPriceData: [],
            itemQuantityData: 0,
            itemProductCodeData: [],
            pageLodingFlag: false,
            billAddress: [],
            shippingAddress: [],
            setShippingAddress: {},
            setBillingAddress: [],
            userInfo: Auth.getUserInfo(),
            rowId: 0,
            patternRowId: [],
            selectedShippingAddress: [],
            slideItemFormFlag: false,
            slidePatternFormFlag: false,
            addRow: false,
            orderLineNumber: 0,
            currentUserInfo: Auth.getLoggedUserInfo(),
            customer_reference: '',
            docDueDate: new Date(),
            orderDetailData: [],
            orderExpenses: [],
            showDetailModal: false,
            itemCodeList: [],
            patternCheckFlag: [],
            patternRowLengthCalcFlag: false,
            patternCalcuRowData: [],
            editPatternCalcuRow: [],
            stockItemData: [],
            patternCalculateCheck: true,
            orderSubmitFlag: false,
            orderApproveFlag: false,
            docEntry: '',
            orderLineNum: 0,
            itemCustomerRefData: [],
            quantityFocusFlag: false,
            showNewItemModal: false,
            setItemCodeFlag: false,
            editOrderRowFlag: false,
            editOrderRowData: [],
            editRowId: '',
            noItemMessage: '',
            ItemCode: [],
            totalLength: 0,
            lanes: 0,
            length: 0,
            laneLength: 0,
            Terms_And_Conditions: '',
            U_DBS_EXPORTKLANT: localStorage.getItem('U_DBS_EXPORTKLANT'),
            ShippingType: '',
            ShippingMethods: [],
            HelpTextModal: false,
            slideShippingAddressFlag: false,
            editShippingAddressFlag: false,
            U_EIJFF_PORTAL_ADRESBOEK: '',
            AddShippingAddress: false,
            Countries: [],
            CountryName: '',
            billingCountry: '',
            NoPatternCalculationChecked: false,
            orderHeaderId: '',
            orderLineId: '',
            U_EIJFF_PORTAL_ANDERLAND: '',
            partialSupply: false,
            requireDocur:JSON.parse(localStorage.getItem('eijf_loggedUser')),
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this.getCustomerData();
        this.getShippingAddresses(null);
        this.getShippingMethods();
        this.getCountries();
        this.getOrderHeader();
    }

    getOrderHeader = () => {
        let OrderHeaderShippingAddress = [];
        var headers = SessionManager.shared().getAuthorizationHeader();
        var userLanguage = localStorage.getItem('eijf_language');
        var userAddressId = localStorage.getItem('eijf_shippingAddress') ? localStorage.getItem('eijf_shippingAddress') : '';
        var languageList = {
            "Dutch": "NL",
            "English": "EN",
            "French": "FR",
            "German": "DE",
        }
        Axios.post(API.PostOrderHeader + "?addressId=" + userAddressId + "&language=" + languageList[userLanguage] +"&ordertype=B2B", {}, headers)
            .then(result => {
                let id = result.data[0];
                this.setState({ orderHeaderId: id });
                Axios.get(API.GetOrderHeader + '?orderHeaderId=' + id, headers)
                    .then(result => {
                        if (result.data[0]) {
                            let OrderHeader = result.data[0];
                            OrderHeaderShippingAddress.name = OrderHeader.ShippingName ? OrderHeader.ShippingName : ""
                            OrderHeaderShippingAddress.address = OrderHeader.ShippingStreet ? OrderHeader.ShippingStreet : ""
                            OrderHeaderShippingAddress.zipCode = OrderHeader.ShippingZip ? OrderHeader.ShippingZip : ""
                            OrderHeaderShippingAddress.city = OrderHeader.ShippingCity ? OrderHeader.ShippingCity : ""
                            OrderHeaderShippingAddress.country = OrderHeader.ShippingCountry ? OrderHeader.ShippingCountry : ""
                            this.setState({ setShippingAddress: OrderHeaderShippingAddress, CountryName: OrderHeader.ShippingCountry, customer_reference: OrderHeader.CustomerReference, shippingCountryCode: OrderHeader.ShippingCountryCode, email: OrderHeader.Email });
                        }
                    })
                    .catch(err => {
                        this.displayError(err)
                    });
            })
            .catch(err => {
                this.displayError(err)
            });
    }

    getCountries = () => {
        var headers = SessionManager.shared().getAuthorizationHeader();

        Axios.get(API.GetCountry, headers)
            .then(result => {

                this.setState({ Countries: result.data });
            })
            .catch(err => {
                this.displayError(err)
            });
    }

    getShippingMethods = () => {
        var headers = SessionManager.shared().getAuthorizationHeader();

        Axios.post(API.PostShippingTypes, {}, headers)
            .then(result => {
                var tempShippingMethods = [];
                result.data.forEach(element => {
                    if (element.TrnspName != '') {
                        tempShippingMethods.push({ "value": element.TrnspCode, "label": element.WebSite })
                    }
                });
                this.setState({ ShippingMethods: tempShippingMethods });
            })
            .catch(err => {
            });
    }

    getHelpText = () => {
        var pageName = 'Place_an_order'
        var headers = SessionManager.shared().getAuthorizationHeader();
        var lang = localStorage.getItem('eijf_lang');
        Axios.get(API.GetHelpTextByName + pageName, headers)
            .then(result => {
                let helpURL = ''
                if (lang==='Dutch') {
                    helpURL = result.data.contentDutch
                } else if (lang==='English') {
                    helpURL = result.data.contentEnglish
                } else if (lang==='French') {
                    helpURL = result.data.contentFrench
                } else {
                    helpURL = result.data.contentGerman
                }
                const a = document.createElement("a");
                a.setAttribute('href', helpURL);
                a.setAttribute('target', '_blank');
                a.click();
            })
            .catch(err => {
                this.displayError(err)
            })
    }

    getCustomerData = () => {
        this._isMounted = true;
        let params = {};
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.GetCustomerData, params, headers)
            .then(result => {
                if (this._isMounted) {
                    if (result.data.value.length) {
                        let addressData = this.getShippingAddressOptionData(result.data.value);
                        let billingAddress = this.getBillingCountryName(addressData[0][0]);
                        this.setState({
                            businessPartnerOption: result.data.value,
                            billAddress: addressData[0][0], //first bill address related
                            U_DBS_EXPORTKLANT: result.data.value[0] ? result.data.value[0].U_DBS_EXPORTKLANT : localStorage.getItem('U_DBS_EXPORTKLANT'),
                            U_EIJFF_PORTAL_ADRESBOEK: result.data.value[0] ? result.data.value[0].U_EIJFF_PORTAL_ADRESBOEK : '',
                            U_EIJFF_PORTAL_ANDERLAND: result.data.value[0] ? result.data.value[0].U_EIJFF_PORTAL_ANDERLAND : ''
                        });
                    }
                }
            })
            .catch(err => {
                this.displayError(err)
            });
    }

    getBillingCountryName = (billingAddress) => {
        var countryCode = billingAddress ? billingAddress.Country : ''
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.get(API.GetCountryName + countryCode, headers)
            .then(response => {
                this.setState({ billingCountry: response.data.countryInEnglish })
            })
            .catch(err => {
                this.displayError(err)
            });
    }

    getShippingAddresses = (addressInfo) => {
        this._isMounted = true;
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.get(API.GetShippingAddresses + this.state.currentUserInfo.SapCustomerCode, headers)
            .then(response => {
                if (this._isMounted) {
                    if (Object.keys(response.data).length > 0) {
                        var sapAddressesForOptions = [];
                        var realShippingAddresses = [];
                        var realSapAddresses = [];
                        var savedIndex = 0;
                        if (response.data.shippingAddress !== null) {
                            realShippingAddresses = response.data.shippingAddress;
                            if (addressInfo !== null) {
                                savedIndex = realShippingAddresses.findIndex(addr => JSON.stringify(addr) === JSON.stringify(addressInfo));
                            }
                        }
                        if (response.data.sapAddresses !== null) {
                            realSapAddresses = response.data.sapAddresses;
                            sapAddressesForOptions = response.data.sapAddresses.map((data, index) => ({ value: "sap" + index, label: data.Street + " " + data.ZipCode + " " + data.City + " " + data.Country + " " + data.name + " " + data.country }));
                        }
                        let addressesForOptions = response.data.shippingAddress;
                        let realAddresses = [...realShippingAddresses, ...realSapAddresses];
                        if (addressInfo) {
                            this.setState({
                                shippingAddresses: realAddresses,
                                setShippingAddress: addressesForOptions[(addressesForOptions.length - 1)],
                                shippingAddressOption: addressesForOptions,
                                selectedShippingAddress: addressesForOptions[savedIndex],
                            })
                        } else {
                            this.setState({
                                shippingAddresses: realAddresses,
                                shippingAddressOption: addressesForOptions,
                                selectedShippingAddress: addressesForOptions[savedIndex],
                            })
                        }
                    }
                }
            })
            .catch(err => {
                this.displayError(err)
            });
    }
    getShippingAddressOptionData = (optionData) => {
        let returnOptionData = [];
        let billAddress = [];
        // let shippingAddress = [];
        optionData.map((data, index) => {
            data.BPAddresses.map((bpAddress, key) => {
                if (bpAddress.AddressName === "Bill to") {
                    billAddress.push(bpAddress);
                }
                // else if(bpAddress.AddressName==="Ship To"){
                //     shippingAddress.push(bpAddress)
                // }
                return bpAddress;
            })
            return data;
        });
        returnOptionData[0] = billAddress;
        // returnOptionData[1] = shippingAddress;
        return returnOptionData;
    }

    onSubmitOrder = () => {
        this._isMounted = true;
        const { rows, businessPartnerOption, billAddress, setShippingAddress, customer_reference, email } = this.state;
        this.setState({ pageLodingFlag: true });
        let documentRowArray = [];
        var postOrderDetailData = {};

        if (rows) {
            let RowArray = {};
            rows.map((row, index) => {
                RowArray = {
                    ItemCode: row.ItemCode ? row.ItemCode : '',
                    ItemName: row.ItemName ? row.ItemName : '',
                    order_quantity: row.order_quantity,
                    order_deliveryWeek: row.order_deliveryWeek ? row.order_deliveryWeek : '',
                    order_customerreference: row.order_customerreference ? row.order_customerreference : '',
                    order_price: row.order_price ? row.order_price : 0,
                    Image: row.Image,
                    Picture: row.Picture,
                    OrderLineId: row.orderLineId,
                }
                documentRowArray.push(RowArray);
                return row;
            })
        }
        postOrderDetailData = {
            user_customerreference: customer_reference ? customer_reference : '',
            businessPartnerOption: businessPartnerOption[0] ? businessPartnerOption[0].CardName : '',
            user_billAddress: billAddress ? billAddress : null,
            user_shippingAddress: setShippingAddress ? setShippingAddress : null,
            documentRowArray: documentRowArray,
            email: email
        }

        this.getExpenses()
        this.setState({ orderDetailData: postOrderDetailData });
    }

    getVatGroups = () => {
        var headers = SessionManager.shared().getAuthorizationHeader();

        return Axios.get(API.GetVatGroups, headers)
        .then(result => {
            return result;
        })
        .catch(err => {
            console.log(err);
        });
    }

    getExpenses = async () => {
        const { rows, ShippingType, orderHeaderId, setShippingAddress, CountryName, customer_reference, partialSupply, shippingCountryCode, email } = this.state;
        const vatCode = this.state.businessPartnerOption[0].VatGroup ?? this.state.rows[0].SalesVATGroup;
        let docTotal = 0;
        let vatSum = 0;
        let ItemCodeArray = [];
        let ExpensesParams = {}

        const DocDate = new Date()
        const headers = SessionManager.shared().getAuthorizationHeader();
        
        rows.map((row, index) => {
            ItemCodeArray.push({ "itemCode": row.ItemCode ? row.ItemCode : '' })
            docTotal += ((row.order_price ? row.order_price : 0) * row.order_quantity);
            return row;
        })

        if(vatCode !== null) {
            const vatGroups = await this.getVatGroups();
            const vatGroupsData = vatGroups.data;
            const currentVatGroup = vatGroupsData.find(x => x.Code === vatCode);
            let rate = 21;

            if(currentVatGroup) {
                rate = currentVatGroup.VatGroups_Lines[0].Rate;
            }

            vatSum = ((docTotal / 100) * rate);
            docTotal = docTotal + vatSum;
        }

        ExpensesParams = {
            "requestId": uuid(),
            "docDate": Moment(DocDate).format('YYYY-MM-DD'),
            "cardCode": this.state.businessPartnerOption[0] ? this.state.businessPartnerOption[0].CardCode : '',
            "docCur": this.state.requireDocur && this.state.requireDocur.Currency ? this.state.requireDocur.Currency :"EUR",
            "u_DBS_OrderType": "B2B",
            "trnspCode": ShippingType ? ShippingType.value : this.state.currentUserInfo.ShippingType,
            "u_DBS_ORDEREXPENSE": "Y",
            "countryS": shippingCountryCode,
            "docTotal": docTotal,
            "vatSum": vatSum,
            "documentLines": ItemCodeArray
        }

        Axios.post(API.PostExpenses, ExpensesParams, headers)
            .then(result => {
                if (result.data.expenses) {
                    const headers = SessionManager.shared().getAuthorizationHeader();
                    const orderExpenses = result.data.expenses ? result.data.expenses[0] : [];
                    const params = {
                        "orderheaderid": orderHeaderId ? orderHeaderId : '',
                        "customreference": customer_reference ? customer_reference : '',
                        "shippingname": setShippingAddress.name ? setShippingAddress.name : '',
                        "shippingstreet": setShippingAddress.address ? setShippingAddress.address : '',
                        "shippingzip": setShippingAddress.zipCode ? setShippingAddress.zipCode : '',
                        "shippingcity": setShippingAddress.city ? setShippingAddress.city : '',
                        "shippingcountry": setShippingAddress.country ? setShippingAddress.countr : (CountryName ? CountryName : ''),
                        "partialSupply": partialSupply === 'on' ? true : false,
                        "expensecode": orderExpenses ? orderExpenses.code : '',
                        "expense": orderExpenses ? orderExpenses.expense : 0,
                        "expensevatcode": orderExpenses ? orderExpenses.vatCode : '',
                        "expenserate": orderExpenses ? orderExpenses.rate : 0,
                        "trnspcode": ShippingType ? ShippingType.value : this.state.currentUserInfo.ShippingType,
                        "email": email
                    }

                    if (setShippingAddress?.ShippingName === ''
                        || setShippingAddress?.address === ''
                        || setShippingAddress?.ShippingZip === ''
                        || setShippingAddress?.ShippingCity === ''
                        || CountryName === '') {
                            Sweetalert({
                                text: "Shippping Address Error!",
                                icon: "error",
                            })
                            return
                    }
                    
                    Axios.put(API.UpdateOrderHeader, params, headers)
                        .then(response => {
                            this.setState({ showDetailModal: true, pageLodingFlag: false })
                            this.setState({ orderExpenses: result.data.expenses[0] ? result.data.expenses[0] : {} });
                        })
                        .catch(err => {
                            this.displayError(err)
                        });
                }
            })
    }

    confirmOrderLines = (partialSupply) => {
        const { orderHeaderId, setShippingAddress, CountryName, customer_reference, orderExpenses, ShippingMethods, ShippingType, email } = this.state;
        this._isMounted = true;
        this.setState({ partialSupply: partialSupply, orderApproveFlag: true });
        let params = {
            "orderheaderid": orderHeaderId ? orderHeaderId : '',
            "customreference": customer_reference ? customer_reference : '',
            "shippingname": setShippingAddress.name ? setShippingAddress.name : '',
            "shippingstreet": setShippingAddress.address ? setShippingAddress.address : '',
            "shippingzip": setShippingAddress.zipCode ? setShippingAddress.zipCode : '',
            "shippingcity": setShippingAddress.city ? setShippingAddress.city : '',
            "shippingcountry": setShippingAddress.country ? setShippingAddress.country : (CountryName ? CountryName : ''),
            "partialSupply": partialSupply === 'on' ? true : false,
            "expensecode": orderExpenses ? orderExpenses.code : '',
            "expense": orderExpenses ? orderExpenses.expense : 0,
            "expensevatcode": orderExpenses ? orderExpenses.vatCode : '',
            "expenserate": orderExpenses ? orderExpenses.rate : 0,
            "trnspcode": ShippingType ? ShippingType.value : this.state.currentUserInfo.ShippingType,
            "email": email
        }
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.put(API.UpdateOrderHeader, params, headers)
            .then(response => {
                var settings = {
                    "url": API.PostOrderSP + orderHeaderId,
                    "method": "POST",
                    "headers": {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + Auth.getUserToken(),
                    }
                }
                $.ajax(settings).done(function (response) {
                })
                    .then(response => {
                        this.setState({ showDetailModal: false, orderApproveFlag: false });
                        Sweetalert(trls('Success'), {
                            icon: "success",
                        }).then((value) => {
                            this.props.blankdispatch(this.props.blankFlag);
                            window.location.reload();
                        });
                    })
                    .catch(err => {
                        this.props.blankdispatch(this.props.blankFlag);
                        this.setState({ showDetailModal: false, orderApproveFlag: false });
                        window.location.reload();
                    });
            })
            .catch(err => {
                this.displayError(err)
            });
    }

    updateOrderHeaderByPartial = (partialSupply) => {
        const { orderHeaderId, setShippingAddress, CountryName, customer_reference, orderExpenses, ShippingMethods, ShippingType, email } = this.state;
        this._isMounted = true;
        this.setState({ pageLodingFlag: true, partialSupply: partialSupply });
        let params = {
            "orderheaderid": orderHeaderId ? orderHeaderId : '',
            "customreference": customer_reference ? customer_reference : '',
            "shippingname": setShippingAddress.name ? setShippingAddress.name : '',
            "shippingstreet": setShippingAddress.address ? setShippingAddress.address : '',
            "shippingzip": setShippingAddress.zipCode ? setShippingAddress.zipCode : '',
            "shippingcity": setShippingAddress.city ? setShippingAddress.city : '',
            "shippingcountry": setShippingAddress.country ? setShippingAddress.countr : (CountryName ? CountryName : ''),
            "partialSupply": partialSupply === 'on' ? true : false,
            "expensecode": orderExpenses ? orderExpenses.code : '',
            "expense": orderExpenses ? orderExpenses.expense : 0,
            "expensevatcode": orderExpenses ? orderExpenses.vatCode : '',
            "expenserate": orderExpenses ? orderExpenses.rate : 0,
            "trnspcode": ShippingType ? ShippingType.value : this.state.currentUserInfo.ShippingType,
            "email": email
        }
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.put(API.UpdateOrderHeader, params, headers)
            .then(response => {
                this.setState({ pageLodingFlag: false });
            })
            .catch(err => {
                this.displayError(err)
            });
    }

    setOrderLineData = (deliveriesData) => {
        let documentLineData = [];
        deliveriesData.map((data, index) => {
            data.DocumentLines.map((documentLine, key) => {
                if (documentLine.TreeType === "iSalesTree") {
                    documentLineData.push(documentLine);
                }
                return documentLine;
            })
            data.DocumentLines = documentLineData;
            return data;
        });
        return deliveriesData;
    }

    removeOrderRow = (rowId, orderLineId) => {
        const { rows } = this.state;
        let rowsArr = rows.filter((item, key) => item.rowId !== rowId);
        this.deleteOrderLineId(orderLineId, rowsArr);
    }

    deleteOrderLineId = (orderLineId, rowsArr) => {
        const { rows } = this.state;
        var settings = {
            "url": API.DeleteOrderLine + '?orderlineId=' + orderLineId,
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Auth.getUserToken(),
            }
        }
        $.ajax(settings).done(function (response) {
        })
        .then(response => {
            this.setState({
                rows: rowsArr ? rowsArr : rows,
            });
        })
        .catch(err => {
            this.displayError(err)
        });
    }

    deleteOrderLinIdByNewItem = (orderLineId, mode) => {
        this.setState({ showNewItemModal: false, itemQuantityData: '', itemData: '', patternCalculateCheck: true, setItemCodeFlag: false, itemSearchformFlag: false, noItemMessage: '' })
        if (!mode) {
            this.deleteOrderLineId(orderLineId);
        }
    }

    removeOredrLine = () => {
        this.setState({ slidePatternFormFlag: false, showNewItemModal: false, itemQuantityData: 0, itemData: '', patternCalculateCheck: true, setItemCodeFlag: false, itemSearchformFlag: false });
    }

    searchItemForm = (itemCode, orderLineNumber) => {
        let orderLineNum = this.state.orderLineNumber;
        this.setState({ slideItemFormFlag: true, itemCode: itemCode, editPatternCalcuRow: [], orderLineNumber: orderLineNumber ? orderLineNumber : orderLineNum })
    }

    setOrderItem = (itemList) => {
        // this.setState({itemData: itemList[0], itemCode: itemList[0].ItemCode, setItemCodeFlag: true}, ()=>{
        //     this.checkPatternCalculate(itemList[0].ItemCode);
        // });
        this.setState({ itemData: itemList, itemCode: itemList.ItemCode, setItemCodeFlag: true }, () => {
            this.checkPatternCalculate(itemList.ItemCode);
        });
    }

    checkPatternCalculate = (itemCode) => {
        let patternCalculateCheck = this.state.patternCalculateCheck;
        let patternCalcuRowData = this.state.patternCalcuRowData;
        const { editOrderRowFlag, editRowId } = this.state;
        this._isMounted = true;
        var settings = {
            "url": API.GetItemData + itemCode,
            "method": "GET",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Auth.getUserToken(),
            }
        }
        $.ajax(settings).done(function (response) {
        })
            .then(response => {
                if (this._isMounted) {
                    if (response.U_DBS_STUKSCONTR === "Y") {
                        patternCalculateCheck = false;
                        this.setState({ slidePatternFormFlag: true, stockItemData: response, itemCode: itemCode });
                    } else {
                        patternCalculateCheck = true;
                        if (editOrderRowFlag) {
                            patternCalcuRowData[editRowId] = []
                            this.setState({ patternCalcuRowData: patternCalcuRowData });
                        }
                    }
                    this.setState({ patternCalculateCheck: patternCalculateCheck })
                }
            })
            .catch(err => {
                this.displayError(err)
                this.searchItemForm(itemCode)
            });
    }

    setLenghQuantity = (length, calcuRowData, NoPatternCalculation) => {
        var headers = SessionManager.shared().getAuthorizationHeader();
        const { rowId, editRowId, editOrderRowFlag } = this.state;
        let patternCalcuRowData = this.state;
        if (editOrderRowFlag) {
            patternCalcuRowData[editRowId] = calcuRowData;
        } else {
            patternCalcuRowData[rowId] = calcuRowData;
        }
        this.setState({ itemQuantityData: length, patternCalcuRowData: patternCalcuRowData, NoPatternCalculationChecked: NoPatternCalculation });
    }

    calculatePattern = (itemData, itemCode) => {
        Common.showSlideForm();
        this.setState({ itemData: itemData, itemCode: itemCode, slidePatternFormFlag: true })
    }

    editShippingAddress = (address) => {
        this.setState({ showShippingAddressModal: true, slideShippingAddressFlag: false, editShippingAddressFlag: true, editShippingAddress: address });
    }

    addOrderRowData = (rowData) => {
        const { rowId } = this.state;
        let rows = this.state.rows;
        rowData.rowId = rowId;
        rows.push(rowData);
        this.setState({ rows: rows });
    }

    onIncreaseQuantity = (rowData) => {
        let rows = this.state.rows;

        try {
            let rowIndex = rows.indexOf(rows.filter((e) => 
                (e.order_customerreference === rowData.order_customerreference) && 
                (e.ItemCode === rowData.ItemCode))[0]
            );
            
            if(rowIndex > -1) {
                const tmpRows = [...this.state.rows];
                tmpRows[rowIndex].order_quantity += rowData.order_quantity;
                this.setState({ rows: tmpRows });
                return;
            }
        } catch (error) {
            console.log(error);
        }
        
        this.setState({ rows: rows });
    }

    newAddOrderLine = () => {
        var headers = SessionManager.shared().getAuthorizationHeader();
        var settings = {
            "url": API.PostSPorderLine + this.state.orderHeaderId,
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Auth.getUserToken(),
            }
        }
        $.ajax(settings).done(function (response) {
        })
            .then(response => {
                if (response) {
                    this.setState({ orderLineId: response[0].orderlineid, noItemMessage: '' })
                }
            })
            .catch(err => {
            });
        let rowId = this.state.rowId;
        this.setState({ showNewItemModal: true, rowId: rowId + 1, orderLineNumber: rowId + 1, editOrderRowFlag: false, editOrderRowData: [], editPatternCalcuRow: [], setItemCodeFlag: false, })
    }

    editOrderRowLine = (editOrderData, editRowId) => {
        let patternCalcuRowData = this.state.patternCalcuRowData;
        this.setState({ editOrderRowFlag: true, editOrderRowData: editOrderData, itemQuantityData: editOrderData.order_quantity, editPatternCalcuRow: patternCalcuRowData[editRowId] ? patternCalcuRowData[editRowId] : [], showNewItemModal: true, editRowId: editRowId })
    }

    updateOrderRowLine = (itemRowData) => {
        const { editRowId } = this.state;
        let updateRowData = [];
        let rows = this.state.rows;
        rows.map((row, index) => {
            if (row.rowId === editRowId) {
                itemRowData.rowId = editRowId;
                row = itemRowData;
            }
            updateRowData.push(row);
            return row
        })
        this.setState({ rows: updateRowData, itemData: itemRowData, itemCode: itemRowData.ItemCode });
    }

    saveShippingAddress = () => {
        const { currentUserInfo, setShippingAddress, CountryName } = this.state;
        this._isMounted = true;
        var headers = SessionManager.shared().getAuthorizationHeader();
        var params = {
            customerNumber: currentUserInfo.SapCustomerCode,
            name: setShippingAddress.hasOwnProperty("id") ? setShippingAddress.name : setShippingAddress.name,
            address: setShippingAddress.hasOwnProperty("id") ? setShippingAddress.address : setShippingAddress.address,
            zipCode: setShippingAddress.hasOwnProperty("id") ? setShippingAddress.zipCode : setShippingAddress.zipCode,
            city: setShippingAddress.hasOwnProperty("id") ? setShippingAddress.city : setShippingAddress.city,
            // countryName: setShippingAddress.hasOwnProperty("id") ? setShippingAddress.countryName: setShippingAddress.countryName,
            country: setShippingAddress.hasOwnProperty("id") ? setShippingAddress.country : setShippingAddress.country,
        };
        Axios.post(API.PostShippingAddress, params, headers)
            .then(response => {
                if (this._isMounted) {
                    this.getShippingAddresses(response.data);
                }
            })
            .catch(err => {
                this.displayError(err)
            });
    }

    setTotalLen = (TotalLength, lanes, length, laneLength) => {
        this.setState({ totalLength: TotalLength, lanes: lanes, length: length, laneLength: laneLength })
    }

    onShow = () => {
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.get(API.GetTermsOfService, headers)
            .then(result => {
                var resultData = result.data;
                var lang = localStorage.getItem('eijf_lang');
                if (this._isMounted) {
                    switch (lang) {
                        case 'English':
                            this.setState({
                                Terms_And_Conditions: resultData.find(x => x.termOfServiceKey === 'TermOfServiceEn').termOfServiceUrl
                            })
                            break;
                        case 'Dutch':
                            this.setState({
                                Terms_And_Conditions: resultData.find(x => x.termOfServiceKey === 'TermOfServiceNl').termOfServiceUrl
                            })
                            break;
                        case 'German':
                            this.setState({
                                Terms_And_Conditions: resultData.find(x => x.termOfServiceKey === 'TermOfServiceDe').termOfServiceUrl
                            })
                            break;
                        case 'French':
                            this.setState({
                                Terms_And_Conditions: resultData.find(x => x.termOfServiceKey === 'TermOfServiceFr').termOfServiceUrl
                            })
                            break;
                        default:
                            this.setState({
                                HelpTextDescription: ''
                            })
                    }
                }
            })
            .catch(err => {
                this.displayError(err)
            })
    }

    changeShippigType = (SelectedShippingType) => {
        this.setState({
            ShippingType: SelectedShippingType
        })
        this.getExpenses()
    }

    updateShippingAddress(ShippingAddress) {
        { this.setState({ setShippingAddress: ShippingAddress }) }
        const { currentUserInfo, AddShippingAddress } = this.state;

        var country = ShippingAddress.country ? this.getCountryName(ShippingAddress) : null;
        var headers = SessionManager.shared().getAuthorizationHeader();
        var params = {}
        var updateID = this.state.editShippingAddress.id
        if (ShippingAddress) {
            params = {
                customerNumber: currentUserInfo.SapCustomerCode,
                name: ShippingAddress.name ? ShippingAddress.name : '',
                address: ShippingAddress.address ? ShippingAddress.address : '',
                zipCode: ShippingAddress.zipCode ? ShippingAddress.zipCode : '',
                city: ShippingAddress.city ? ShippingAddress.city : ShippingAddress.City,
                country: country ? country : (ShippingAddress.countryName ? ShippingAddress.countryName : ShippingAddress.country)
            };
        }
        if (ShippingAddress.country) {
            if (AddShippingAddress) {
                Axios.post(API.PostShippingAddress, params, headers)
                    .then(response => {
                        if (this._isMounted) {
                            this.getShippingAddresses(response.data);
                            this.setState({
                                AddShippingAddress: false
                            })
                        }
                    })
                    .catch(err => {
                    });
            }
            else {
                Axios.put(API.PutShippingAddress + updateID, params, headers)
                    .then(response => {
                    })
                    .catch(err => {
                        this.displayError(err)
                    });
            }
        }
        
        this.getCountryName(ShippingAddress);
        //this.UpdateHeader(ShippingAddress);
    }

    setShippingAdress = (shippingAddress) => {
        this.setState({ setShippingAddress: shippingAddress });
        let customerReference = this.state.customer_reference
        this.UpdateHeader(shippingAddress,customerReference);
    }

    UpdateHeader = (shippingAddress,customerReference) => {
        const { orderHeaderId, customer_reference, CountryName, partialSupply, orderExpenses, ShippingType, ShippingMethods, email } = this.state;
        let country = null;

        if(shippingAddress.country) {
            const countries = this.state.Countries.filter((e) => e.countryCode === shippingAddress.country);
            
            if(countries.length) {
                country = countries[0].countryInEnglish;
            }
        }
        
        let params = {
            "orderheaderid": orderHeaderId ? orderHeaderId : '',
            "customreference": customerReference ? customerReference : customer_reference ? customer_reference : '',
            "shippingname": shippingAddress.name ? shippingAddress.name : '',
            "shippingstreet": shippingAddress.address ? shippingAddress.address : '',
            "shippingzip": shippingAddress.zipCode ? shippingAddress.zipCode : '',
            "shippingcity": shippingAddress.city ? shippingAddress.city : '',
            "shippingcountry": country ? country : (CountryName ? CountryName : ''),
            "partialSupply": partialSupply === "on" ? true : false,
            "expensecode": orderExpenses ? orderExpenses.code : '',
            "expense": orderExpenses ? orderExpenses.expense : 0,
            "expensevatcode": orderExpenses ? orderExpenses.vatCode : '',
            "expenserate": orderExpenses ? orderExpenses.rate : 0,
            "trnspcode": ShippingType ? ShippingType.value : this.state.currentUserInfo.ShippingType,
            "email": email
        }
        var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.put(API.UpdateOrderHeader, params, headers)
            .then(response => {
                
            })
            .catch(err => {
                this.displayError(err)
            });
    }

    changeCustomerReference = (customerReference) => {
        const { setShippingAddress } = this.state;
        this.setState({ customer_reference: customerReference });
        this.UpdateHeader(setShippingAddress);
    }

    AddNewShippingAddress = () => {
        this.setState({
            showShippingAddressModal: true,
            editShippingAddressFlag: true,
            slideShippingAddressFlag: false,
            editShippingAddress: {},
            AddShippingAddress: true
        })
    }

    getCountryName = (ShippingAddress) => {
        var countryCode = ShippingAddress ? ShippingAddress.country : null;
        var headers = SessionManager.shared().getAuthorizationHeader();

        if (countryCode) {
            Axios.get(API.GetCountryName + countryCode, headers)
                .then(response => {
                    this.setState({
                        CountryName: response.data.countryInEnglish
                    })
                })
                .catch(err => {
                    this.displayError(err)
                });
        }
    }

    displayError = (err) => {
        if (err.response) {
            if (err.response.status === 401) {
                history.push('/login')
            }
            else {
                Sweetalert({
                    text: "Something went wrong, please try again later",
                    icon: "error",
                })
            }
        }
    }

    render() {
        let totalAmount = 0;
        const { businessPartnerOption,
            shippingAddressOption,
            itemPriceData,
            pageLodingFlag,
            billAddress,
            setShippingAddress,
            setBillingAddress,
            itemQuantityData,
            rows,
            selectedShippingAddress,
            slideItemFormFlag,
            slidePatternFormFlag,
            patternCalculateCheck,
            // patternCalcuRowData,
            itemData,
            setItemCodeFlag,
            editOrderRowFlag,
            editPatternCalcuRow,
            editOrderRowData,
            showNewItemModal,
            // editRowId,
            noItemMessage,
            slideShippingAddressFlag,
            editShippingAddress,
            editShippingAddressFlag,
            U_EIJFF_PORTAL_ADRESBOEK,
            Countries,
            U_DBS_EXPORTKLANT,
            CountryName,
            billingCountry,
            U_EIJFF_PORTAL_ANDERLAND,
            newOrderDisabled
        } = this.state;
        let showPrice = localStorage.getItem('eijf_showPrice') === "true" ? true : false;
        if (itemPriceData) {
            rows.map((data, key) => {
                totalAmount += data.order_price ? data.order_price * data.order_quantity * 1 : 0;
                return data
            })
        }
        return (
            <div className="order_div">
                <div className="content__header content__header--with-line">
                    <div id="google_translate_element"></div>
                    <h2 className="title">{trls("Place_an_order")}</h2>
                </div>
                <Row>
                    <Col sm={6}></Col>
                    <Col sm={6}>
                        <Button variant="primary" className="float-right" onClick={this.getHelpText}><i className="fa fa-info-circle" aria-hidden="true"></i></Button>
                    </Col>
                </Row>
                <Container>
                    <Form className="container product-form" onSubmit={this.handleSubmit}>
                        <Row className="order__info-bill mt-5">
                            <Col sm={6} style={{ paddingLeft: 0, paddingTop: 10 }}>
                                <Form.Group as={Row} controlId="formPlaintextPassword">
                                    <Form.Label column sm="4">
                                        {trls("CReference")}
                                    </Form.Label>
                                    <Col sm="8" className="product-text">
                                        <Form.Control type="text" name="reference" required  onChange={(evt) => this.changeCustomerReference(evt.target.value)} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextPassword">
                                    <Form.Label column sm="4">
                                        {trls("Business_partner")}
                                    </Form.Label>
                                    <Col sm="8" className="product-text">
                                        <Form.Control type="text" name="reference" required defaultValue={businessPartnerOption[0] ? businessPartnerOption[0].CardName : ''} readOnly placeholder={trls('Customer_reference')} />
                                    </Col>
                                </Form.Group>

                            </Col>
                            <Col sm={3}>
                                <div className="place-order__address">
                                    <p className="address-header">{trls('Billing_Address')}</p>
                                    <p>{billAddress.Block ? billAddress.Block : ""}</p>
                                    <p>{billAddress.City ? billAddress.Street + " " + (billAddress.StreetNo ? billAddress.StreetNo : '') : ''}</p>
                                    <p>{billAddress.City ? billAddress.ZipCode + " " + billAddress.City : ''}</p>
                                    <p>{billingCountry ? billingCountry : ''}</p>
                                </div>
                            </Col>
                            <Col sm={3} className="place-order-shipping-address">
                                <Col className="place-order__address">

                                    {setShippingAddress ?
                                        <div >
                                            <p className="address-header">{trls('Shipping_Address')}
                                                <span className="shipping-address_edit">

                                                    <i className="fas fa-edit add-icon " onClick={() => U_EIJFF_PORTAL_ADRESBOEK == 'Y' ? this.setState({ slideShippingAddressFlag: true }) : this.setState({ showShippingAddressModal: true })} />{U_EIJFF_PORTAL_ADRESBOEK === 'Y' ? <i className="fas fa-save save-icon" onClick={this.saveShippingAddress} /> : null}
                                                </span>
                                            </p>
                                            <p>{setShippingAddress.name ? setShippingAddress.name : ''}</p>
                                            <p>{setShippingAddress.address ? setShippingAddress.address : ''}</p>
                                            <p>{(setShippingAddress.zipCode ? setShippingAddress.zipCode : '') + " " + (setShippingAddress.city ? setShippingAddress.city : '')}</p>
                                            <p>{this.state.Countries.filter((e) => e.countryCode === setShippingAddress.country).length ? this.state.Countries.filter((e) => e.countryCode === setShippingAddress.country)[0].countryInEnglish : (CountryName ? CountryName : setShippingAddress.country)}</p>
                                        </div>
                                        :
                                        <div >
                                            <p className="address-header">{trls('Shipping_Address')}
                                                <span className="shipping-address_edit">
                                                    <i className="fas fa-edit add-icon " onClick={() => this.setState({ slideShippingAddressFlag: true })} />{this.state.U_EIJFF_PORTAL_ADRESBOEK === "Y" && <i className="fas fa-save save-icon" onClick={this.saveShippingAddress} />}
                                                </span>
                                            </p>
                                        </div>
                                    }

                                </Col>
                            </Col>
                        </Row>
                    </Form>
                    <div className="table-responsive order-row">
                        <table id="example" className="place-and-orders__table table table--striped prurprice-dataTable" width="100%">
                            <thead>
                                <tr>
                                    <th>{trls("Product_code")}</th>
                                    <th>{trls("Product_description")}</th>
                                    {/* <th>{trls("Unit")}</th> */}
                                    <th>{trls("Quantity")}</th>
                                    {showPrice ? (
                                        <th>{trls("Price")}</th>
                                    ) : null}
                                    {showPrice ? (
                                        <th>{trls("Amount")}</th>
                                    ) : null}
                                    <th>{trls("Image")}</th>
                                    <th>{trls("Customer_reference")}</th>
                                    <th>{trls("Action")}</th>
                                </tr>
                            </thead>
                            {rows && (<tbody>
                                {
                                    rows.map((data, index) => (
                                        <tr id={index} key={index}>
                                            <td style={{ display: "flex" }}>
                                                <Form.Control id="itemCode" type="text" name="productcode" readOnly placeholder={trls('Product_code')} value={data.ItemCode ? data.ItemCode : ''} />
                                            </td>
                                            <td>
                                                <Form.Control type="text" name="description" className="place-order_description" readOnly value={data.ItemName ? data.ItemName : ''} placeholder={trls('Description')} />
                                            </td>
                                            { !data.patterCalculateCheck ? (
                                                <td style={{ display: "flex" }}>
                                                    <Form.Control type="text" name="quantity" className="place_an_orrder-quantity-y" readOnly value={data.order_quantity ? Number.parseFloat(data.order_quantity).toFixed(2) : ''} placeholder={trls('Quantity')} />
                                                </td>
                                            ) :
                                                <td style={{ display: "flex" }}>
                                                    <Form.Control type="text" name="quantity" className="place_an_orrder-quantity" ref="quantity" readOnly value={data.order_quantity ? Number.parseFloat(data.order_quantity).toFixed(2) : ''} placeholder={trls('Quantity')} />
                                                </td>
                                            }
                                            {showPrice ? (
                                                <td >
                                                    <div style={{ width: 80 }}>{Common.formatMoney(data.order_price ? data.order_price : '')}</div>
                                                </td>
                                            ) : null}
                                            {showPrice ? (
                                                <td style={{ width: 100 }}>
                                                    {Common.formatMoney(data.order_price ? data.order_price * data.order_quantity : '')}
                                                </td>
                                            ) : null}
                                            <td>
                                                {data.Image && (
                                                    <img src={data.Image ? "data:image/png;base64," + data.Image : ''} className="image__zoom" alt={data.rowId} onError={(e) => {
                                                        e.target.style.display = 'none';
                                                    }}></img>
                                                )
                                                }
                                            </td>
                                            <td>
                                                <Form.Control type="text" name="customerReference" className="place-order_Customer-reference" readOnly value={data.order_customerreference ? data.order_customerreference : ''} placeholder={trls('Customer_reference')} />
                                            </td>
                                            <td>
                                                <Row style={{ justifyContent: "start" }}>
                                                        {/*<i className="fas fa-edit add-icon" onClick={() => this.editOrderRowLine(data, data.rowId)}></i>*/}
                                                        <i className="fas fa-trash-alt add-icon btn-primary px-3" style={{paddingTop: '8px'}} onClick={() => this.removeOrderRow(data.rowId, data.orderLineId)}></i>
                                                </Row>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>)}
                        </table>
                        {this.state.loading && (
                            <div className="col-md-4 offset-md-4 col-xs-12 loading" style={{ textAlign: "center" }}>
                                <BallBeat
                                    color={'#222A42'}
                                    loading={this.state.loading}
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <Button variant="light" onClick={() => this.newAddOrderLine()}><i className="fas fa-plus add-icon"></i>{trls('Click_to_make_new_row')}</Button>
                    </div>
                    {showPrice ? (
                        <Col sm={4} className="info-block info-block--green">
                            <span className="txt-bold">{trls('Order total')}</span>
                            <span>{totalAmount ? Common.formatMoney(totalAmount) : Common.formatMoney(totalAmount)}</span>
                        </Col>
                    ) : null}
                    <div style={{ textAlign: "right", height: 50 }}>
                        <Button type="button" className="place-submit__order" disabled={rows.length <= 0} onClick={() => this.onSubmitOrder(null, true)}>{trls('Submit Order')}</Button>
                    </div>

                </Container>
                {showNewItemModal && (
                    <Newitemform
                        show={this.state.showNewItemModal}
                        onHide={(orderLineId, mode) => this.deleteOrderLinIdByNewItem(orderLineId, mode)}
                        getItemData={() => this.getItemData()}
                        searchItemForm={(itemCode) => this.searchItemForm(itemCode)}
                        checkPatternCalculate={(itemCode) => this.checkPatternCalculate(itemCode)}
                        onSetItemCodeFlag={() => this.setState({ setItemCodeFlag: false, noItemMessage: '' })}
                        onAddOrderRow={(rowData) => this.addOrderRowData(rowData)}
                        onIncreaseQuantity={(rowData) => this.onIncreaseQuantity(rowData)}
                        itemQuantityData={itemQuantityData}
                        itemData={itemData}
                        patternCalculateCheck={patternCalculateCheck}
                        slidePatternFormFlag={slidePatternFormFlag}
                        setItemCodeFlag={setItemCodeFlag}
                        itemSearchformFlag={slideItemFormFlag}
                        editOrderRowFlag={editOrderRowFlag}
                        editOrderRowData={editOrderRowData}
                        editPatternCalcuRow={editPatternCalcuRow}
                        updateOrderRowLine={(itemData) => this.updateOrderRowLine(itemData)}
                        calculatePattern={() => this.setState({ slidePatternFormFlag: true })}
                        noItemMsg={noItemMessage}
                        orderLineId={this.state.orderLineId}
                    />
                )}

                {slideItemFormFlag ? (
                    <ItemSearchform
                        onHide={() => this.setState({ slideItemFormFlag: false, temQuantityData: '' })}
                        onSetItemData={(itemList) => this.setOrderItem(itemList)}
                        itemCode={this.state.itemCode}
                        noItemMsg={noItemMessage}
                        setNoItemMessage={() => this.setState({ noItemMessage: '' })}
                    />
                ) : null}
                {slidePatternFormFlag ? (
                    <Patterncalculateform
                        onHide={() => this.setState({ slidePatternFormFlag: false })}
                        removeOrderLine={() => this.removeOredrLine()}
                        orderLineNumber={this.state.orderLineNumber}
                        itemData={this.state.stockItemData}
                        itemCode={this.state.itemCode}
                        editPatternCalcuRow={editPatternCalcuRow}
                        patternRowLengthCalcFlag={this.state.patternRowLengthCalcFlag}
                        onSetQuantity={(length, patternCalcuRowData, NoPatternCalculationChecked) => this.setLenghQuantity(length, patternCalcuRowData, NoPatternCalculationChecked)}
                        setTotalLen={(TotalLen, lanes, length, laneLength) => this.setTotalLen(TotalLen, lanes, length, laneLength)}
                        orderLineId={this.state.orderLineId}
                    />
                ) : null}
                {slideShippingAddressFlag ? (
                    <ShippingAddressEdit
                        shippingAddressOption={shippingAddressOption}
                        onHide={() => this.setState({ slideShippingAddressFlag: false })}
                        editShippinOption={(Address) => this.editShippingAddress(Address)}
                        AddNewShippingAddress={() => this.AddNewShippingAddress()}
                    />
                ) : null}

                <Orderdetailform
                    onShow={() => this.onShow()}
                    show={this.state.showDetailModal}
                    countries={this.state.Countries}
                    onHide={() => this.setState({ showDetailModal: false, orderApproveFlag: false })}
                    orderDetailData={this.state.orderDetailData}
                    orderExpenses={this.state.orderExpenses}
                    approveOrder={(partialSupply) => this.confirmOrderLines(partialSupply)}
                    updateOrderHeaderByPartial={(partialSupply) => this.updateOrderHeaderByPartial(partialSupply)}
                    orderApproveFlag={this.state.orderApproveFlag}
                    Terms_And_Conditions={this.state.Terms_And_Conditions}
                    changeShippigType={(val) => this.changeShippigType(val)}
                    U_DBS_EXPORTKLANT={U_DBS_EXPORTKLANT}
                    ShippingMethods={this.state.ShippingMethods}
                    totalAmount={totalAmount}
                    CountryName={CountryName}
                    billingCountry={billingCountry}
                    changeEmail={(email) => this.setState({email: email})}
                />
                {editShippingAddressFlag ?
                    <Shippingaddressform
                        show={this.state.showShippingAddressModal}
                        onHide={() => this.setState({ showShippingAddressModal: false })}
                        shippingAddress={editShippingAddress}
                        setShippingAddress={(shippingAddress) => this.updateShippingAddress(shippingAddress)}
                        editShippingAddress={editShippingAddressFlag}
                        Countries={Countries}
                        U_DBS_EXPORTKLANT={U_DBS_EXPORTKLANT}
                        U_EIJFF_PORTAL_ANDERLAND={U_EIJFF_PORTAL_ANDERLAND}
                        CountryName={CountryName}
                    />
                    :
                    <Shippingaddressform
                        show={this.state.showShippingAddressModal}
                        onHide={() => this.setState({ showShippingAddressModal: false })}
                        shippingAddress={setShippingAddress}
                        // setShippingAddress={(shippingAddress)=>{this.setState({setShippingAddress: shippingAddress})}}
                        setShippingAddress={(shippingAddress) => this.setShippingAdress(shippingAddress)}
                        editShippingAddress={editShippingAddressFlag}
                        Countries={Countries}
                        U_DBS_EXPORTKLANT={U_DBS_EXPORTKLANT}
                        U_EIJFF_PORTAL_ANDERLAND={U_EIJFF_PORTAL_ANDERLAND}
                        CountryName={CountryName}
                    />
                }

                <Pageloadspiiner loading={pageLodingFlag} text={''}/>
                <HelpTextModal
                    show={this.state.HelpTextModal}
                    onHide={() => this.setState({ HelpTextModal: false })}
                    HelpTextTitle={this.state.PlaceOrderHelpTextTitle}
                    HelpTextDescription={this.state.HelpTextDescription}
                />
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Placemanage);