/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { trls } from "../../factories/translate";
import "react-datepicker/dist/react-datepicker.css";
import API from "../../factories/api";
import * as Common from "../../factories/common";
import Axios from "axios";
import * as Auth from "../../factories/auth";
import SessionManager from "../../factories/session_manage";
import $ from "jquery";
import Pageloadspiiner from "../../components/page_load_spinner";
import history from "../../history";
import Sweetalert from "sweetalert";

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({});

class Newitemform extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    const { editOrderRowData } = this.props;
    this.state = {
      itemCode: editOrderRowData.ItemCode ? editOrderRowData.ItemCode : "",
      itemData: [],
      itemFlag: false,
      pageLodingFlag: false,
      quantity: editOrderRowData.order_quantity
        ? editOrderRowData.order_quantity
        : "",
      customerReference: editOrderRowData.order_customerreference
        ? editOrderRowData.order_customerreference
        : "",
      itemEnable: false,
      IfSset: false,
      ItemsGroupCode: 0,
      productItemCode: "",
    };
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    $(document).on("keypress", ":input", function (e) {
      if (e.which === 13) e.preventDefault();
    });
    $(".modal").removeAttr("tabindex");
  }

  handleSubmit = (event) => {
    this.setState({ pageLodingFlag: true });
    const {
      patternCalculateCheck,
      itemQuantityData,
      editPatternCalcuRow,
      orderLineId,
      itemData,
      itemSearchformFlag,
    } = this.props;
    const { itemFlag, productItemCode, itemEnable } = this.state;
    this._isMounted = true;
    event.preventDefault();
    const clientFormData = new FormData(event.target);
    const data = {};
    for (let key of clientFormData.keys()) {
      data[key] = clientFormData.get(key);
    }
    // let quantity = 0;
    // if (
    //   (patternCalculateCheck && !editPatternCalcuRow.rowId) ||
    //   (itemFlag && !itemQuantityData)
    // ) {
    //   quantity = parseFloat(data.quantity);
    // } else if (itemQuantityData) {

    //   quantity = parseFloat(itemQuantityData);
    // }

    if(data.quantity === undefined || data.quantity === 0) {
      Sweetalert({
        text: trls(
          "Item quantity has to be higher than 0"
        ),
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
      this.setState({ pageLodingFlag: false });

      return;
    }

    var headers = SessionManager.shared().getAuthorizationHeader();
    let params = [];
    if (itemEnable) {
      params = {
        orderlineid: orderLineId,
        productcode: productItemCode ? productItemCode : "",
        quantity: parseFloat(data.quantity),
        customerreference: data.customerreference ? data.customerreference : "",
      };
    } else {
      params = {
        orderlineid: orderLineId,
        productcode: itemData.ItemCode ? itemData.ItemCode : "",
        quantity: parseFloat(data.quantity),
        customerreference: data.customerreference ? data.customerreference : "",
      };
    }
    if (orderLineId) {
      Axios.put(API.UpdateOrderLine, params,headers)
        .then((result) => {
          let lineId = orderLineId;
          let duplicateItem = false;

          if(result.data.length > 0) {
            if(result?.data[0]?.OrderlineId) {
              if(orderLineId !== result.data[0].OrderlineId) {
                lineId = result.data[0].OrderlineId;
                this.setState({editOrderRowFlag: true});
                duplicateItem = true;
              }
            }
          }

          Axios.get(API.GetObsolete+lineId,headers).then((result) => {
            let status = result.data.status;
            if (
              status === "Not obsolete" ||
              status === "Obsolete but with stock"
            ) {
              var settings = {
                url: API.GetCalculateDataPrice+lineId,
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + Auth.getUserToken(),
                },
              };
              $.ajax(settings)
                .then((response) => {
                  this.setOrderItem(
                    data.itemcode,
                    parseFloat(data.quantity),
                    response.discountPriceResponse["NewUnitPrice"],
                    data.customerreference,
                    response.deliveryDate,
                    "",
                    duplicateItem
                  );
                })
                .catch((err) => {
                  if (err.response.status === 401) {
                    history.push("/login");
                  }
                });
            } else {
              Sweetalert({
                text: trls(
                  "Item has been discontinued and is not sufficiently available"
                ),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                var settings = {
                  url: API.DeleteOrderLine + "?orderlineId=" + lineId,
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Auth.getUserToken(),
                  },
                };
                this.setState({ pageLodingFlag: true });
                $.ajax(settings)
                .done(function (response) {})
                .then((response) => {
                  this.setState({ pageLodingFlag: false });
                  this.onHide(true);
                })
                .catch((err) => {
                  this.displayError(err);
                });
              });
            }
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            history.push("/login");
          }
        });
    }
  };
  setOrderItem = (
    itemCode,
    quantity,
    price,
    customerreference,
    deliveryWeek,
    priceData,
    update
  ) => {
    const {
      setItemCodeFlag,
      patternCalculateCheck,
      editOrderRowFlag,
      editOrderRowData,
      orderLineId,
    } = this.props;
    const { itemFlag } = this.state;
    var headers = SessionManager.shared().getAuthorizationHeader();
    this._isMounted = true;
    let itemData = [];
    if (itemCode) {
      if (this._isMounted) {
        if (!editOrderRowFlag || itemFlag) {
          if (!setItemCodeFlag) {
            itemData = this.state.itemData;
          } else {
            itemData = this.props.itemData;
          }
        } else {
          if (setItemCodeFlag) {
            itemData = this.props.itemData;
          } else {
            itemData = editOrderRowData;
          }
        }
        if (!itemData.ItemCode) {
          this.setState({ itemEnable: false });
          return;
        }
        Axios.get(API.GetItemDataByItemCode + itemData.ItemCode, headers)
          .then((result) => {
            itemData.Image = result.data.Image;
            itemData.order_quantity = quantity;
            itemData.order_price = price;
            itemData.orderPriceData = priceData;
            itemData.order_customerreference = customerreference;
            itemData.patterCalculateCheck = patternCalculateCheck;
            itemData.order_deliveryWeek = deliveryWeek;
            itemData.orderLineId = orderLineId;
            this.setState({ pageLodingFlag: false });


            if(update) {
              this.props.onIncreaseQuantity(itemData);
            } else if (!editOrderRowFlag) {
              this.props.onAddOrderRow(itemData);
            } else {
              this.props.updateOrderRowLine(itemData);
            }
            this.onHide(true);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              history.push("/login");
            }
          });
      }
    } else {
      Sweetalert({
        text: "Invalid item code",
        icon: "error",
      });
    }
  };

  getItemData = () => {
    var headers = SessionManager.shared().getAuthorizationHeader();
    this._isMounted = true;
    const state = this;
    let itemCodeData = "";
    const { itemCode } = this.state;
    const { setItemCodeFlag, itemData } = this.props;
    if (setItemCodeFlag) {
      itemCodeData = itemData.ItemCode;
    } else {
      itemCodeData = this.state.IfSset ? "S" + itemCode : itemCode;
    }
    let code = 0;
    let obj = this;
    if (itemCodeData !== "") {
      code = itemCodeData;
      this.setState({ pageLodingFlag: true });
      var settings = {
        url: API.GetSampleItemByItemCode + code,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Auth.getUserToken(),
        },
      };
      $.ajax(settings)
        .done(function (response) {})
        .catch((error) => {})
        .then(function (response) {
          if (response) {
            obj.setState(
              {
                itemData: response,
                itemEnable: true,
                itemFlag: true,
                pageLodingFlag: false,
                ItemsGroupCode: response.ItemsGroupCode,
                productItemCode: response.ItemCode,
              },
              () => {
                $(".fade").attr("tabindex", "disable");
              }
            );
          } else {
            let isCode = "";
            if (obj.state.IfSset) {
              isCode = code;
            } else {
              isCode = "S" + code;
              obj.setState({ IfSset: true });
            }
            var settings = {
              url: API.GetSampleItemByItemCode + isCode,
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Auth.getUserToken(),
              },
            };
            $.ajax(settings)
              .done(function (response) {})
              .then((response) => {
                obj.setState(
                  {
                    itemData: response,
                    itemFlag: true,
                    pageLodingFlag: false,
                    itemEnable: true,
                    ItemsGroupCode: response.ItemsGroupCode,
                    productItemCode: isCode,
                  },
                  () => {
                    $(".fade").attr("tabindex", "disable");
                  }
                );
              })
              .then(response => {
                state.changeQuantity(state.state.quantity || 1, state.state.ItemsGroupCode);
              })
              .catch((err) => {
                obj.setState({ pageLodingFlag: false, itemEnable: false });
                $(".fade").attr("tabindex", "disable");
                obj.props.searchItemForm(itemCodeData);
              });
          }
        });
    }
  };

  addtionalGetItemData = (code, itemCodeData) => {
    var headers = SessionManager.shared().getAuthorizationHeader();
  };

  showSearchItem = () => {
    let itemCodeData = "";
    const { itemCode } = this.state;
    const { setItemCodeFlag, itemData } = this.props;
    if (setItemCodeFlag) {
      itemCodeData = itemData.ItemCode;
    } else {
      itemCodeData = itemCode;
    }
    if (this.state.IfSset) {
      this.props.searchItemForm("S" + itemCodeData);
    } else {
      this.props.searchItemForm(itemCodeData);
    }
    $(".fade").attr("tabindex", "disable");
  };

  changeItemCode = (e) => {
    if (e.target.value.charAt(0) === "s" || e.target.value.charAt(0) === "S") {
      this.setState({
        itemCode: e.target.value.substring(1),
        IfSset: true,
        codeChange: true,
      });
    } else {
      this.setState({
        itemCode: e.target.value,
        codeChange: 1,
        codeChange: true,
      });
    }
    // this.props.onSetItemCodeFlag();
  };

  onHide = () => {
    this.setState({
      itemCode: "",
      itemData: [],
      itemFlag: false,
      pageLodingFlag: false,
      quantity: "",
    });
    this.props.onHide();
  };

  changeQuantity = (value, itemGroup) => {
    const quantityValue = parseInt(value);

    if(quantityValue > 100) {
      Sweetalert({
        text: trls(
          "Is this number correct?"
        ),
        icon: "warning",
      })
    }

    if(itemGroup === 106) {
      this.setState({quantity: 0.3});
    }

    if(itemGroup === 107) {
      if(quantityValue < 1 || quantityValue.toString().includes("0")) {
        this.setState({ quantity: 1 });
      } else if (quantityValue > 5) {
        if(quantityValue > 9 && quantityValue.toString().slice(-1) < 6) {
          this.setState({ quantity: quantityValue.toString().slice(-1) });
        } else {
          this.setState({ quantity: 5 });
        }
        Sweetalert({
          text: trls(
            "The maximum number of samples is 5."
          ),
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
      } else {
        this.setState({ quantity: quantityValue })
      }
    } else {
      if(quantityValue > 0 && quantityValue < 9999) {
        this.setState({ quantity: quantityValue })
      }
    }
  }

  getProductQuantity = (quantity, group) => {
    if(group.toString() === "106") {
      this.setState({ quantity: 0.3 });
      return 0.3;
    }

    if(quantity === "") {
      this.setState({quantity: 1});
      return 1;
    }

    if(group.toString() === "107") {
      if(parseInt(quantity) < 1) {
        this.setState({ quantity: 1 });
        return 1;
      } else if (parseInt(quantity) > 5) {
        this.setState({ quantity: 5 });
        return 5;
      }
    }
  }

  render() {
    const {
      quantity,
      itemFlag,
      itemCode,
      pageLodingFlag,
      customerReference,
      itemEnable,
      IfSset,
      ItemsGroupCode,
    } = this.state;
    const {
      itemQuantityData,
      itemData,
      setItemCodeFlag,
      itemSearchformFlag,
      editPatternCalcuRow,
      searchItemsGroupCode,
    } = this.props;
    let itemCodeData = "";
    if (setItemCodeFlag) {
      if (
        itemData.ItemCode.charAt(0) === "S" ||
        itemData.ItemCode.charAt(0) === "s"
      ) {
        itemCodeData = itemData.ItemCode.substring(1);
      } else {
        itemCodeData = itemData.ItemCode;
      }
    } else {
      if (itemCode.charAt(0) === "S" || itemCode.charAt(0) === "s") {
        itemCodeData = itemCode.substring(1);
      } else {
        itemCodeData = itemCode;
      }
    }
    return (
      <Modal
        show={this.props.show}
        onHide={() => this.onHide()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.editOrderRowFlag ? trls("Edit"): trls("New Item")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group as={Row}>
              <Col className="product-text">
                <Form.Control
                  type="text"
                  name="itemcode"
                  autoComplete="off"
                  required
                  disabled={itemSearchformFlag || this.props.editOrderRowFlag}
                  value={itemCodeData}
                  className={
                    !itemEnable
                      ? "place-order__product-code active"
                      : "place-order__product-code"
                  }
                  placeholder={trls("Product_code")}
                  onChange={(e) => this.changeItemCode(e)}
                  onBlur={this.getItemData}
                />
                <label className="placeholder-label">
                  {trls("Product_code")}
                </label>
                <i
                  className="fas fa-search place-an-order__loop"
                  aria-hidden="true"
                  onClick={() => this.showSearchItem()}
                ></i>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col className="product-text">
                {/* <Form.Control type="text" name="quantity" required disabled={ (!patternCalculateCheck || itemSearchformFlag) || (editPatternCalcuRow.rowId && !itemFlag) ? true : false} value={ !patternCalculateCheck || itemQuantityData ? itemQuantityData : quantity} placeholder={trls('Quantity')} onChange={(e)=>this.setState({quantity: e.target.value})}/> */}
                <Form.Control
                  type="number"
                  name="quantity"
                  id="itemQuantity"
                  required
                  disabled={false}
                  value={(ItemsGroupCode === 106 || searchItemsGroupCode === 106) ? 0.3 : this.state.quantity}
                  placeholder={trls("Quantity")}
                  onChange={(e) => this.changeQuantity(e.target.value, ItemsGroupCode ? ItemsGroupCode : searchItemsGroupCode)}
                  readOnly={ItemsGroupCode === 106 || searchItemsGroupCode === 106}
                />
                <label className="placeholder-label">{trls("Quantity")}</label>
                {/* { editPatternCalcuRow.rowId && !itemFlag && (
                                <i className="fas fa-pen place-an-order__loop" aria-hidden="true" onClick={()=>this.editPatternCalculate()}></i>
                            )} */}
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Col className="product-text">
                <Form.Control
                  type="text"
                  name="customerreference"
                  defaultValue={customerReference}
                />
                <label className="placeholder-label">
                  {trls("Customer_reference")}
                </label>
              </Col>
            </Form.Group>
            <Form.Group style={{ textAlign: "center" }}>
              <Button
                type="Submit"
                disabled={
                  ItemsGroupCode === 107 ||
                  ItemsGroupCode === 106 ||
                  searchItemsGroupCode === 106 ||
                  searchItemsGroupCode === 107
                    ? false
                    : true
                }
              >
                {trls("Submit")}
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Pageloadspiiner loading={pageLodingFlag} text={""} />
      </Modal>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Newitemform);
